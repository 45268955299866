<template>
  <div class="slider">
    <div class="slider__container d-sm-flex">
      <div
        class="slider__description"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-offset="300"
      >
        <div
          v-for="(tab, index) in data"
          :key="index"
          :class="[activeTab === index ? 'active' : 'slider__control']"
          @click="changeTab(index)"
        >
          <div class="slider___control__item">
            <div class="slider__control__content">
              <span class="slider__control__content-number"
                >0{{ index + 1 }}</span
              >
              <div class="slider___control__detail">
                <span>{{ tab.step }}</span>
                <div v-if="!!tab.content" class="slider___control__detail-sub">
                  <ul>
                    <li v-for="(content, indx) in tab.content" :key="indx">
                      <p class="slider___control__detail-content">
                        {{ content }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <img
              :src="tab.icon"
              alt="slide-icon"
              width="60"
              height="60"
              v-if="isDesktop"
            />
          </div>
        </div>
      </div>
      <div
        class="slider__block"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-offset="300"
      >
        <div v-for="(slide, index) in data" :key="index">
          <div v-if="activeTab === index" class="slider__block-item">
            <img
              v-lazy="slide.background"
              alt="Background Image"
              width="640"
              height="360"
            />
            <span>{{ slide.sub }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Slider',
  data() {
    return {
      isDesktop: window.innerWidth >= 768,
      window: 0,
      activeTab: 0
    };
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    }
  },
  props: {
    data: []
  },
  mounted() {},
  watch: {
    lang() {}
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    }),
    columns() {
      return 1;
    }
  }
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}

.slider {
  .slider__container {
    display: flex;
    gap: 80px;
    width: 100%;
  }
  .slider__description {
    width: 50%;
    flex: 1;
  }
  .slider__control {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:hover {
      background-color: #fff3ee;
    }
  }
  .slider___control__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 420px;
    padding: 16px 24px;
    border-radius: 10px;
    white-space: initial;
    gap: 16px;
    cursor: pointer;
    max-width: 100%;
    img {
      display: none;
    }
  }
  .slider__control__content {
    display: flex;
    align-items: center;
    gap: 16px;
    text-transform: none;
    .slider__control__content-number {
      font-weight: 700;
      font-size: 24px;
      color: var(--brown-text);
    }
  }
  .slider___control__detail span {
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    line-height: 26px;
    color: var(--brown-text);
  }
  .slider___control__detail-sub {
    display: none;
  }
  .slider__block {
    flex: 1;
    height: auto;
    position: relative;
    padding: 0;
    z-index: 0;
    transition: all 0.2s linear;
    overflow: hidden;
    border-radius: 5px;
    min-width: 300px;
    .slider__block-item {
      animation: slide-up 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 0.2s linear;
    }
    span {
      display: inline-block;
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      white-space: pre-line;
      color: var(--grey-text);
    }
    img {
      width: 100%;
      border-radius: 10px;
      transition: all 0.2s;
    }
  }
  .active .slider___control__item {
    background-color: #fee3d3;
    position: relative;
    transition: all 0.3s ease-out;
    span {
      font-weight: 700;
      font-size: 44px;
      line-height: 42px;
      color: var(--orange);
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 3px;
      height: 85%;
      background-color: var(--orange);
    }
    img {
      display: block;
    }
  }
  /* .active .slider__control__content {
    align-items: flex-start;
  } */
  .active .slider___control__detail {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    & > span {
      line-height: 1;
      font-weight: 700;
      font-size: 20px;
      color: var(--blue-text);
    }
  }
  .active .slider___control__detail-sub {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 18px;
    color: var(--grey-text);
    ul {
      margin-bottom: 0;
      padding: 0;
    }
    li {
      display: flex;
      align-items: baseline;
      gap: 8px;
    }
    .slider___control__detail-content {
      font-size: 18px;
      margin-bottom: 0;
      line-height: 1.2;
      color: var(--grey-text);
      font-weight: 400;
      text-align: left;
      position: relative;
      &::before {
        position: absolute;
        left: -16px;
        top: 8px;
        content: '';
        background-color: tomato;
        height: 4px;
        width: 4px;
        border-radius: 50%;
      }
    }
  }
}
@media (max-width: 1024px) {
  .slider .slider__container {
    gap: 10px;
  }
  .slider {
    padding-top: 50px;
  }
  .slider__container {
    flex-direction: column-reverse;
  }
  .slider__description {
    width: 100% !important;
  }
  .slider__title {
    font-size: 32px;
  }
  .slider .slider___control__item {
    width: 100%;
  }
  .slider .active .slider___control__item img {
    display: none;
  }
}
@media (max-width: 768px) {
  .slider {
    padding-top: 20px;
    .slider___control__item {
      min-width: fit-content;
    }
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
